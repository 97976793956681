.nav-tabs {
    border-bottom: 0px;
}

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
    color: #fff !important;
}

.loader-hidden {
    display: none;
}
.loader-active {
    display: block;
    text-align: center;
}

.loader-inner > div {
    background-color: #009efb !important;
}

.main-content {
    background: #f2f7f8;
}

.fade.in {
    opacity: 1;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
}

.modal-backdrop.in {
     opacity:.5;
}

.footer {
    left: 0px;
}
